<template>
<div class="stocks-view owf-h white-bg">
    <div class="stocks-top-view pt-200">
        <h1 class="top-h1" data-aos="fade-up" v-if="!loading"> {{Stock.data[`title_${$i18n.locale}`]}} </h1>
        <p class="top-p mt-20px" data-aos="fade-up" v-if="!loading"> {{Stock.data[`description_${$i18n.locale}`]}} </p>
        <el-skeleton v-if="loading" />
        <div class="stocks-top-bg" v-if="Stock.files.image">
            <img :src="`https://avior.prestigeautofl.com${Stock.files.image}`" alt="">
        </div>
         <div class="stocks-top-bg" v-else>
            <img src="@/assets/img/stockbg.svg" alt="">
        </div>
    </div> 
    <div class="flex justify-center">
        <div class="stock mw-1440 pt-0 ">
            <div class="flex justify-center w-full">
                <div class="stock-list pt-0 stock-mover" v-if="StockList.length>0 && !loading ">
                    <div class="stock-card-item pt-0 stock-pb-60" v-for="item in StockList" :key="item.id">
                        <div class="stock-phone-bg" data-aos="zoom-in" :style="{'background':`url(${item.image?'https://avior.prestigeautofl.com'+item.image:require('@/assets/img/bg1.png')})`}">
                            <div class="stock-phone-bg-cover"></div>
                        </div>
                        <div class="stock-card">
                            <div class="stock-card-bg" :style="{'background':`url(${item.image?'https://avior.prestigeautofl.com'+item.image:require('@/assets/img/bg1.png')})`}"></div>
                            <div class="stock-card-text"> 
                                <h1> {{item[`title_${$i18n.locale}`]}} </h1>
                                <p> {{item[`title_${$i18n.locale}`]}} </p>
                            </div>
                            <div class="stock-card-bottom">
                                <button @click="$router.push(`/stocks/${item.id}`)">{{$t('stock_show')}}</button>
                                <p v-if="item.start_date" >{{item.start_date.split('-')[2] }}.{{item.start_date.split('-')[1]}} - {{item.end_date.split('-')[2]}}.{{item.end_date.split('-')[1]}}.{{item.end_date.split('-')[0]}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="w-full flex justify-center mt-40px">
                        <button class="button gray flex items-center" v-if="total>a" @click="a=a+6"> {{$t('more')}}  <img class="ml-10" src="@/assets/icons/down.svg" alt=""> </button>
                    </div>
                </div>
                <div class="w-full not-stoscs" v-if="StockList.length==0 && !loading ">
                    <h1>We have not active stocks</h1>
                </div> 
                <div class="stock-list pt-0 stock-mover" v-if="loading">
                        <div class="stock-card-item pt-0 stock-pb-60" v-for="i in a" :key="i">
                            <div class="stock-phone-bg" data-aos="zoom-in" ></div>
                            <div class="stock-card" data-aos="fade-up">
                                <div class="stock-card-bg" :style="{'background':`url(${require('@/assets/img/bg1.png')})`}"></div>
                                <div class="stock-card-text">
                                    <el-skeleton :rows="3" animated />
                                    <el-skeleton :rows="2" animated />
                                </div>
                                <div class="stock-card-bottom">
                                    <el-skeleton :rows="2" animated />
                                    <el-skeleton :rows="4" animated />
                                </div>

                            </div>
                        </div>
                </div>
            </div>
            </div>
        </div>

    </div>
</template>

<script>
 export default {
     data() {
         return {
             a: 6,
             StockList: [], 
             totla: 0,
             Test: [],
             loading: false, 
         }
     },
     watch: {
         a(val) {
             this._GET(val)
         }
     },
     computed:{
         Stock(){
             return this.$store.state.Stock
         }
     },
     methods: {

         _GET(i) {
             this.loading = true
             this.axios.get(`/stock?per_page=${i}`)
                 .then(res => {
                     this.StockList = res.data.data.data;
                     this.total = res.data.data.total
                 })
                 .finally(() => {
                     this.loading = false
                 })
         },
         
     },
     mounted() {
         this._GET(6) 
     }
 }
</script>

<style lang="scss">
.not-stoscs {
    padding: 50px 20px;
    display: flex;
    justify-content: center;

    h1 {
        font-family: Stem-Medium;
        font-size: 40px;
        line-height: 42px;
        color: var(--other-black);
    }
}

.stocks-top-view {
    width: 100%;
    text-align: center;
    background: var(--bg-top);
    padding: 100px 120px 250px 120px;
    position: relative;
}

.stock-mover {
    transform: translateY(-100px);
}

.stocks-top-bg {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    img {
        width: 30%;
    }
}

.stock-pb-60 {
    padding-bottom: 60px !important;
}

@media screen and(max-width:1100px) {
    .stocks-top-view {
        padding: 100px 20px 250px 20px;
    }
}

@media screen and(max-width:1440px) {

    .stock-pb-60 {
        padding-bottom: 20px !important;
    }
}

@media screen and(max-width:768px) {
    .stocks-top-view {
        padding: 100px 20px 150px 20px;
    }

    .stocks-top-bg {
        display: none;
    }
}
</style>
